<template>
    <div class="membership-purchase" :dir="direction">
        <h2>{{ $t(`message.buy_vip`) }}</h2>
        <div class="plans">
            <div v-for="(plan, index) in plans" :key="index" :class="['plan', { selected: selectedPlan === index }]"
                @click="selectPlan(index)">
                <div class="plan-duration">{{ formatDuration(plan.duration) }}</div>
                <div class="plan-price">{{ formatPrice(plan.amount, plan.currency) }}</div>
                <!-- <div>{{ formatPerDay(plan.amount, plan.duration, plan.currency) }}</div> -->
            </div>
        </div>
        <div class="plan-control">
            <div class="send-to-friend">
                <label for="sendToFriend">{{ $t(`message.sendToFriend`) }}</label>
                <input type="checkbox" v-model="sendToFriend" id="sendToFriend" :disabled="noEmail" />
                <label for="sendToFriend" class="checkbox-label"></label>
            </div>
            <div class="quantity">
                <label for="quantity">{{ $t(`message.buyAmount`) }}</label>
                <div class="quantity-controls">
                    <button @click="decreaseQuantity">-</button>
                    <input type="number" v-model="quantity" min="1" id="quantity" readonly />
                    <button @click="increaseQuantity">+</button>
                </div>
            </div>
        </div>
        <form class="pay-form" ref="payeerForm" @submit.prevent="submitForm" action="https://payeer.com/merchant/"
            method="post">
            <input type="hidden" name="m_shop" :value="m_shop" />
            <input type="hidden" name="m_orderid" :value="m_orderid" />
            <input type="hidden" name="m_amount" :value="m_amount" />
            <input type="hidden" name="m_curr" :value="m_curr" />
            <input type="hidden" name="m_desc" :value="m_desc" />
            <input type="hidden" name="m_sign" :value="sign" />
            <input type="hidden" name="m_params" :value="m_params" />
            <input type="hidden" name="m_cipher_method" value="AES-256-CBC-IV" />
            <button class="pay" type="submit">
                <span class="main-text">{{ $t(`message.pay_payeer_1`) }}</span><br />
                <span class="sub-text">{{ $t(`message.pay_payeer_2`) }}</span>
            </button>
        </form>

        <form class="pay-form" ref="stripeForm" action="/api/stripe_create_checkout_session" method="post"
            style="margin-top: 10px;">
            <input type="hidden" name="product" :value="selectedProduct" />
            <input type="hidden" name="quantity" :value="quantity" />
            <input type="hidden" name="sendToFriend" :value="sendToFriend" />
            <input type="hidden" name="user_email" :value="userEmail" />
            <button class="pay" type="submit">
                <span class="main-text">{{ $t(`message.pay_stripe_1`) }}</span><br />
                <span class="sub-text">{{ $t(`message.pay_stripe_2`) }}</span>
            </button>
        </form>

        <form v-if="enable_webmoney" class="pay-form" method="POST"
            action="https://merchant.webmoney.ru/lmi/payment_utf.asp" accept-charset="utf-8" style="margin-top: 10px;">
            <input type="hidden" name="LMI_PAYMENT_AMOUNT" :value="totalAmount">
            <input type="hidden" name="LMI_PAYMENT_DESC" :value="totalDesc">
            <input type="hidden" name="LMI_PAYMENT_NO" :value="webMoneyNumber">
            <input type="hidden" name="LMI_PAYEE_PURSE" value="Z801257121916">
            <!-- <input type="hidden" name="LMI_SIM_MODE" value="0"> -->
            <input type="hidden" name="product" :value="selectedProduct" />
            <input type="hidden" name="quantity" :value="quantity" />
            <input type="hidden" name="sendToFriend" :value="sendToFriend">
            <input type="hidden" name="user_email" :value="userEmail">
            <button class="pay" type="submit">
                <span class="main-text">{{ $t(`message.pay_webmoney_1`) }}</span><br />
                <span class="sub-text">{{ $t(`message.pay_webmoney_2`) }}</span>
            </button>
        </form>
    </div>

    <!-- <footer>
        <div class="footer-links">
            <a href="https://sites.google.com/view/amanlinkprivatepolicy"><u>Privacy Policy</u></a>
            <a href="https://sites.google.com/view/aman-link-user-agreement"><u>User Agreement</u></a>
            <a href="https://t.me/amanlinkvpn"><u>Telegram</u></a>
            <a href="https://www.instagram.com/amanlinkvpn/"><u>Instagram</u></a>
            <a href="mailto:mobile221106@gmail.com"><u>Email</u></a>
        </div>
        <p>&copy; 2024 AmanLink. All rights reserved.</p>
    </footer> -->
</template>

<script>
import axios from 'axios';
import { ElMessage } from 'element-plus';

export default {
    computed: {
        direction() {
            // List of RTL language codes
            const rtlLanguages = ['ar', 'he', 'fa', 'ur', 'az', 'ckb', 'ps', 'ug', 'dv'];
            // Check if the current locale is in the list of RTL languages
            return rtlLanguages.includes(this.$i18n.locale) ? 'rtl' : 'ltr';
        }
    },
    data() {
        return {
            m_shop: '2109356894',
            m_orderid: '1',
            m_amount: 0,
            m_curr: 'USD',
            m_desc: this.encodeDescription('Test'),
            sign: '',
            m_params: '',

            plans: [
                // { product: '1d', duration: 1, amount: 0.01, currency: 'USD', desc: '1 day' },
                { product: '1w', duration: 7, amount: 1.00, currency: 'USD', desc: '1 week' },
                { product: '1m', duration: 30, amount: 1.99, currency: 'USD', desc: '1 month' },
                { product: '3m', duration: 90, amount: 4.99, currency: 'USD', desc: '3 month' },
                { product: '1y', duration: 365, amount: 16.99, currency: 'USD', desc: '1 year' }
            ],
            selectedPlan: 0,
            sendToFriend: false,
            noEmail: false,
            quantity: 1,

            selectedProduct: '',
            userEmail: '',

            totalAmount: 0,
            totalDesc: '',
            webMoneyNumber: 0,
        };
    },
    created() {
        this.user_email = this.getQueryParam('user_email');
        this.enable_webmoney = this.getQueryParam('webmoney');
        this.enable_webmoney = 1 // remove this if want to enable test switch

        this.userEmail = this.user_email;
        if (this.user_email === null || this.user_email.length == 0) {
            this.sendToFriend = true
            this.noEmail = true
        }

        this.selectedProduct = this.plans[this.selectedPlan].product;
        this.totalAmount = this.plans[this.selectedPlan].amount * this.quantity;
        this.totalAmount = (this.totalAmount * 1.3).toFixed(2)
        this.totalDesc = 'AmanLink VPN ' + this.plans[this.selectedPlan].desc + ' * ' + this.quantity;
        this.totalDesc = this.totalDesc + " (+30% exchange fees for WebMoney)"
        this.webMoneyNumber = this.generateRandomNumber();
    },
    methods: {
        formatAmount(amount) {
            return amount.toFixed(2);
        },
        encodeDescription(description) {
            return btoa(description);
        },
        getQueryParam(param) {
            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get(param);
        },
        async generateParamsAndSign() {
            const params = {
                reference: {
                    product: this.plans[this.selectedPlan].product,
                    quantity: this.quantity.toString(),
                    sendToFriend: this.sendToFriend.toString()
                }
            };

            if (this.user_email) {
                params.reference.user_email = this.user_email;
            }

            const requestData = {
                m_shop: this.m_shop,
                m_amount: this.m_amount,
                m_curr: this.m_curr,
                m_desc: this.m_desc,
                params: params,
            };

            try {
                const response = await axios.post('/api/get_payeer_url', requestData);
                this.m_params = response.data.m_params;
                this.sign = response.data.m_sign;
                this.m_orderid = response.data.m_orderid;
                return true
            } catch (error) {
                console.error('Error generating sign and params:', error);
                return false
            }
        },
        async submitForm() {
            // Calculate the amount based on the selected plan and quantity
            this.m_amount = (this.plans[this.selectedPlan].amount * this.quantity).toFixed(2);
            this.m_desc = this.encodeDescription("product: " + this.plans[this.selectedPlan].desc + ", quantity: " + this.quantity);

            // Generate the params and sign
            const success = await this.generateParamsAndSign();

            if (success) {
                this.$nextTick(() => {
                    this.$refs.payeerForm.submit();
                });
            } else {
                alert('Failed to generate sign and params. Please try again later.');
            }
        },
        async submitStripe2() {
            const params = {
                product: this.plans[this.selectedPlan].product,
                quantity: this.quantity.toString(),
                sendToFriend: this.sendToFriend.toString()
            };

            if (this.user_email) {
                params.reference.user_email = this.user_email;
            }

            try {
                const response = await axios.post('/api/stripe_create_checkout_session', params);
                const redirectUrl = response.headers.location;
                if (redirectUrl) {
                    try {
                        window.location.href = redirectUrl;
                    } catch (error) {
                        alert('Failed to redirect to stripe checkout, error: ' + error + ' . Please try again later.');
                    }
                } else {
                    alert('Failed to checkout with stripe, code: ' + response.status);
                }
            } catch (error) {
                alert('Failed to checkout with stripe, error: ' + error + ' . Please try again later.');
            }
        },
        selectPlan(index) {
            this.selectedPlan = index;
            this.selectedProduct = this.plans[this.selectedPlan].product;
            this.totalAmount = this.plans[this.selectedPlan].amount * this.quantity;
            this.totalAmount = (this.totalAmount * 1.3).toFixed(2)
            this.totalDesc = 'AmanLink VPN ' + this.plans[this.selectedPlan].desc + ' * ' + this.quantity;
            this.totalDesc = this.totalDesc + " (+30% exchange fees for WebMoney)"

            this.checkShowWebmoneyLimitToast();
        },
        formatDuration(days) {
            if (days < 30) {
                return `${days} ${this.$t(`message.format_day`)}`;
            } else if (days < 365) {
                return `${Math.floor(days / 30)} ${this.$t(`message.format_month`)}`;
            } else {
                return `${Math.floor(days / 365)} ${this.$t(`message.format_year`)}`;
            }
        },
        formatPrice(amount, currency) {
            return `${currency} ${amount.toFixed(2)}`;
        },
        formatPerDay(amount, duration, currency) {
            const perDay = (amount / duration).toFixed(2);
            return `${this.$t(`message.format_sumary`)}${currency} ${perDay}/${this.$t(`message.format_day`)}`;
        },
        pay() {
            // Handle the payment logic here
            console.log('Selected Plan:', this.plans[this.selectedPlan]);
            console.log('Send to Friend:', this.sendToFriend);
            console.log('Quantity:', this.quantity);
        },
        checkShowWebmoneyLimitToast() {
            if (Number(this.totalAmount) >= 200 && Number(this.quantity) % 5 == 0) {
                ElMessage({
                    message: `${this.$t(`message.toast_webmoney_limit`)}`,
                    type: 'info',
                    duration: 5000,
                });
            }
        },
        decreaseQuantity() {
            if (this.quantity > 1) {
                this.quantity--;

                this.totalAmount = this.plans[this.selectedPlan].amount * this.quantity;
                this.totalAmount = (this.totalAmount * 1.3).toFixed(2)
                this.totalDesc = 'AmanLink VPN ' + this.plans[this.selectedPlan].desc + ' * ' + this.quantity;
                this.totalDesc = this.totalDesc + " (+30% exchange fees for WebMoney)"
            }
        },
        increaseQuantity() {
            if (this.quantity < 100) {
                this.quantity++;

                this.totalAmount = this.plans[this.selectedPlan].amount * this.quantity;
                this.totalAmount = (this.totalAmount * 1.3).toFixed(2)
                this.totalDesc = 'AmanLink VPN ' + this.plans[this.selectedPlan].desc + ' * ' + this.quantity;
                this.totalDesc = this.totalDesc + " (+30% exchange fees for WebMoney)"

                this.checkShowWebmoneyLimitToast();
            }
        },
        generateRandomNumber() {
            const min = 100000000000000;
            const max = 999999999999999;
            const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
            return randomNum;
        }
    }
};
</script>

<style scoped>
.membership-purchase {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    text-align: center;
    /* background: linear-gradient(135deg, #6a11cb 0%, #2575fc 100%); */
    color: black;
    font-family: Arial, sans-serif;
    padding-top: 10%;
    padding-left: 10%;
    padding-right: 10%
}

.plans {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* Two columns */
    gap: 20px;
    /* Space between the grid items */
    margin-bottom: 20px;
    width: 100%;
}

.plan {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.plan-duration {
    font-weight: bold
}

.plan-price {
    color: #03A894
}


.plan.selected {
    background-color: #e0f7fa;
}

.plan-control {
    width: 100%;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    margin-bottom: 24px;
}

.send-to-friend {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.quantity {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #e0e0e0;
    padding: 10px;
}

.send-to-friend label {
    margin: 0;
}

.send-to-friend input[type="checkbox"] {
    display: none;
}

.send-to-friend .checkbox-label {
    width: 24px;
    height: 24px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    display: inline-block;
    position: relative;
    background-color: #f5f5f5;
    cursor: pointer;
}

.send-to-friend input[type="checkbox"]:checked+.checkbox-label {
    background-color: #4caf50;
}

.send-to-friend input[type="checkbox"]:checked+.checkbox-label::after {
    content: '✔';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 16px;
}

.quantity label {
    margin: 0;
}

.quantity-controls {
    display: flex;
    align-items: center;
}

.quantity-controls button {
    width: 30px;
    height: 30px;
    border: 1px solid #e0e0e0;
    background-color: #f5f5f5;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.quantity-controls input[type="number"] {
    width: 40px;
    text-align: center;
    border: none;
    background-color: #fff;
    font-size: 16px;
    margin: 0 5px;
}

.quantity-controls button:hover {
    background-color: #e0e0e0;
}

/* .send-to-friend,
.quantity {
    margin-bottom: 20px;
} */



footer {
    position: fixed;
    bottom: 20px;
    width: 100%;
    text-align: center;
}

.footer-links {
    margin-bottom: 10px;
}

.footer-links a {
    color: black;
    text-decoration: none;
    margin: 0 10px;
}

.footer-links a:hover {
    text-decoration: underline;
}

.pay-form {
    width: 100%
}

input.pay:hover {
    background-color: #03A894;
}

input.pay {
    width: 100%;
    padding: 10px 20px;

    background-color: #03A894;
    font-size: 18px;
    font-weight: bold;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button.pay {
    width: 100%;
    /* Adjust width to fit content */
    padding: 10px 20px;
    background-color: #03A894;
    /* Teal color */
    font-size: 18px;
    /* Base font size */
    font-weight: bold;
    color: white;
    border: none;
    border-radius: 5px;
    /* Rounded corners */
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;
    line-height: 1.2;
    /* Adjust line height for better spacing between lines */
}

button.pay:hover {
    background-color: #028a7c;
    /* Darker teal on hover */
}

button.pay .main-text {
    font-size: 20px;
    /* Larger font size for the main text */
}

button.pay .sub-text {
    font-size: 13px;
    /* Smaller font size for the second line */
    font-weight: normal;
    /* Normal weight for secondary text */
    color: rgba(255, 255, 255, 0.8);
}
</style>