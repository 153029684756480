<template>
    <div class="main-page">
        <header>
            <img src="@/assets/AmanLink.png" alt="Logo" class="logo" />
            <h1>AmanLink VPN</h1>
            <p class="subtitle">The VPN that works fast and reliably in your country.</p>
            <div class="app-store-button">
                <img src="@/assets/googleplay.svg" alt="Download on the Google Play"
                    onclick="window.location.href='https://play.google.com/store/apps/details?id=net.mfree.vx'" />
                <a href="/res/amanlink_latest.apk" class="custom-button">Download APK</a>
                <a href="/buy_activation_code" class="custom-button-key">Buy VPN</a>
            </div>
        </header>
        <footer>
            <div class="footer-links">
                <a href="https://sites.google.com/view/amanlinkprivatepolicy"><u>Privacy Policy</u></a>
                <a href="https://sites.google.com/view/aman-link-user-agreement"><u>User Agreement</u></a>
                <a href="https://t.me/amanlinkvpn"><u>Telegram</u></a>
                <a href="https://www.instagram.com/amanlinkvpn/"><u>Instagram</u></a>
                <a href="mailto:mobile221106@gmail.com"><u>Email</u></a>
            </div>
            <p>&copy; 2024 AmanLink. All rights reserved.</p>
        </footer>
    </div>
</template>

<script>
export default {
    name: 'MainPage',
};
</script>

<style scoped>
.main-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    /* background: linear-gradient(135deg, #6a11cb 0%, #2575fc 100%); */
    color: black;
    font-family: Arial, sans-serif;
}

header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
}

h1 {
    font-size: 2.5rem;
    margin: 0;
}

.subtitle {
    font-size: 1.2rem;
    margin: 10px 0 20px;
}


.app-store-button {
    display: flex;
    gap: 20px;
    /* Adjust the gap size as needed */
}

.app-store-button img {
    padding: 0;
    margin: 0;
    display: block;
    width: 150px;
    height: auto;
}

footer {
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
}

.footer-links {
    margin-bottom: 10px;
}

.footer-links a {
    color: black;
    text-decoration: none;
    margin: 0 10px;
}

.footer-links a:hover {
    text-decoration: underline;
}

.custom-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    color: #1FB57A;
    background-color: white;
    border: 2px solid #1FB57A;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
}

.custom-button:hover {
    background-color: #f0f0f0;
}



.custom-button-key {
    display: inline-block;
    padding: 10px 40px;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background-color: #1FB57A;
    border: 2px solid #1FB57A;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
}

.custom-button-key:hover {
    background-color: #1a9363
}

/* .custom-button {
            display: inline-block;
            padding: 10px 40px;
            font-size: 16px;
            color: white;
            background-color: #03A894;
            border: none;
            border-radius: 5px;
            text-align: center;
            text-decoration: none;
            transition: background-color 0.3s ease;
        }

        .custom-button:hover {
            background-color: #039987;
        } */
</style>